import { GET_USER_DETAILS } from "./ActionTypes";
import { GET_USER_DETAILS_RESPONSE } from "./ActionTypes";


export const getUsetDetails= (getUserDetailsInfo) => ({
  type: GET_USER_DETAILS,
  payload: getUserDetailsInfo,
});

export const getUsetDetailsResponse = (getUserDetailsResponse) => ({
  type: GET_USER_DETAILS_RESPONSE,
  payload: getUserDetailsResponse,
});