import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import RectangleCorveBorder from "../../../assets/images/RectangleCorveBorder.png"
import Styles from "./Index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllDeviceListById,  } from "../../../Redux/Actions";
import { useEffect } from 'react';
import { useState } from 'react';


function createData( name, waterlevel, nextmaintanace,  status, lastUpdate) {

  

  return {  name, waterlevel, nextmaintanace, status, lastUpdate };
}
// const rows = [
//   createData( "My tank #1", "0.5/5M", "09 Dec 2019","PENDING", "0 5:18AM"),
//   createData( "My tank #1", "0.5/5M", "09 Dec 2019","APPROVED", "05:18AM"),
//   createData( "My tank #1", "0.5/5M", "09 Dec 2019","DisAPPROVED", "05:18AM"),
// ];



export default function DataTable() {

  const dispatch = useDispatch();
  const deviceList = useSelector(device => device.DeviceList.deviceDetails)

  const authUser = JSON.parse(sessionStorage.getItem("auth"));


useEffect(() => {
      dispatch(getAllDeviceListById(authUser?.userId)); 
  }, []);

  const [tableData, setDatbleData]=useState()
  useEffect(() => {
    if (deviceList && deviceList?.statusCode === 200) {
      setDatbleData(deviceList.data.slice(0, 3))
    }
}, [deviceList]);



  return (
    <TableContainer component={Paper} className={Styles.TableComtainerInDashboard}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" className={Styles.TableComtainerInDashboard}>
        <TableHead>
          <TableRow>
          <TableCell align="start"  className={Styles.datatableWaterLevel}></TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>DEVICE NAME</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>WATER LEVEL</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>NEXT MAINTENANCE</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>STATUS</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>LAST UPDATED</TableCell>
            <TableCell align="start"  className={Styles.datatableWaterLevel} ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map((row) => (
            <>
            <TableRow className={Styles.TableRowINDashBoard}>
              <TableCell align="start" className={Styles.TableRowINDashBoardRow}><img src={RectangleCorveBorder} alt=""/></TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}>{row.devicename}</TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}>{((row.tankcapacity / 500 ) * 100)}/5L</TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}>{new Date(`${row.lastmaintanance}`).toLocaleDateString("en-US",{ year: 'numeric', month: 'short', day: 'numeric' })}</TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}>
                            <div
                                style={{
                                color: row.devicestatus === 'INACTIVE' ? '#E46A11' : row.devicestatus === 'ACTIVE' ? '#0D894F' : '#545F71', 
                                backgroundColor: row.devicestatus === 'INACTIVE' ? '#FDF1E8' : row.devicestatus === 'ACTIVE' ? '#E7F4EE' : '#EEF1F4',
                                width : 'fit-content',
                                borderRadius: '100px',
                                padding: '4px 12px', 
                               textAlign:'center',  

                                }}
                            ><div>{row.devicestatus}</div></div>
              </TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}>{new Date(`${row.lastrefill}`).toLocaleTimeString("en-US")}</TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}><MoreVertSharpIcon/></TableCell>
            </TableRow>
            <TableRow>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            </TableRow>
               </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
