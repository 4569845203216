import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import RectangleCorveBorder from "../../../assets/images/RectangleCorveBorder.png"
import Styles from "./Index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { getTransactionHistory } from "../../../Redux/Actions";


function createData( name, waterlevel, nextmaintanace,  status, lastUpdate) {

  

  return {  name, waterlevel, nextmaintanace, status, lastUpdate };
}




export default function PaymentDataTable() {

    let dispatch = useDispatch();

    const getPaymentResponse = useSelector(
        (payment) => payment.GetPayment.transactionDetails
      );

      console.log(getPaymentResponse.data,"transactionDetails")

      useEffect(()=>{
        dispatch(getTransactionHistory(94))
      },[])


  return (
    <TableContainer component={Paper} className={Styles.TableComtainerInDashboard}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" className={Styles.TableComtainerInDashboard}>
        <TableHead>
          <TableRow>
          <TableCell align="start"  className={Styles.datatableWaterLevel}></TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>Amount</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>REF ID</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>Description</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>Date</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>PAYMENT method</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>Status</TableCell>
            <TableCell align="start"  className={Styles.datatableWaterLevel} ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getPaymentResponse.data?.map((row) => (
            <>
            <TableRow className={Styles.TableRowINDashBoard}>
              <TableCell align="start" className={Styles.TableRowINDashBoardRow}><img src={RectangleCorveBorder} alt=""/></TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}>{row.amount}</TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}>{row.reference}</TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}>Subscription update</TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}>{row.createdat}</TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}>Credit Card</TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}>
                            <div
                                style={{
                                color: row.paymentstatus === 'Failed' ? '#E46A11' : row.paymentstatus === 'Success' ? '#0D894F' : '#545F71', 
                                backgroundColor: row.paymentstatus === 'Failed' ? '#FDF1E8' : row.paymentstatus === 'Success' ? '#E7F4EE' : '#EEF1F4',
                                width : 'fit-content',
                                borderRadius: '100px',
                                padding: '4px 12px', 
                               textAlign:'center',

                                }}
                            ><div>{row.paymentstatus}</div></div>
              </TableCell>
              <TableCell align="start"  className={Styles.TableRowINDashBoardRow}><MoreVertSharpIcon/></TableCell>
            </TableRow>
            <TableRow>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
            </TableRow>
               </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
