import React, { useEffect, useRef, useState } from "react";
import Styles from "./Index.module.css";
import LoginImage from "../../../assets/images/LoginImage.png";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as MailIcon } from "../../../assets/SvgIcons/MailIcon.svg";
import MuiPhoneNumber from "material-ui-phone-number";
import { useDispatch, useSelector } from "react-redux";
import { OTPVerify,userCreate,MobileOTPVerify } from "../../../Redux/Actions";
import { Link, useNavigate } from "react-router-dom";

const Registration = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const userCreateResponse = useSelector(state => state.Registration.RegistrationDetails);
  const emailOTPResponse = useSelector((state) => state.OTPVerification.OTPvalidation);
  const mobileOTPResponse = useSelector((state) => state.OTPVerification.MobileOTPValidation);


  const [registration, setRegistration] = useState({
    firstname: "",
    lastName: "",
    email: "",
    password: "",
    phonenumber: "",
    roleid: 1,
    countrycode: "+91",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [registrationOtpOpen, setRegistrationOtpOpen]=useState("Signup")

  const [confirmshowPassword, setConfirmShowPassword] = useState(false);

  const handleClickShowConfirmPassword = () =>
    setConfirmShowPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  // const createNewUser = () => {
  //     dispatch(userCreate(registration))
  //   if (userCreateResponse?.statusCode === 200) {
  //     setRegistrationOtpOpen("emailOtp");
  //     SetEmailotp({ ...emailotp, email: registration.email })
  //     SetMobileotp({...mobileotp, number: registration.phonenumber})
  //   }
  // };

  const createNewUser = () => {
    dispatch(userCreate(registration));
  };
  
  // useEffect to handle the navigation after the user creation response is received
  useEffect(() => {
    if (userCreateResponse?.statusCode === 200) {
      setRegistrationOtpOpen("emailOtp");
      SetEmailotp({ ...emailotp, email: registration.email });
      SetMobileotp({ ...mobileotp, number: registration.phonenumber });
    }
  }, [userCreateResponse]);
  



  //otp validation

  const [emailotp, SetEmailotp] = useState({
    email: "",
    emailotp: "",
  });

  const otp1 = useRef();
  const otp2 = useRef();
  const otp3 = useRef();
  const otp4 = useRef();
  const otp5 = useRef();
  const otp6 = useRef();

  const addOtp = () => {
    let value = 0;
    value =
      otp1.current.value +
      otp2.current.value +
      otp3.current.value +
      otp4.current.value +
      otp5.current.value +
      otp6.current.value;
    SetEmailotp({ ...emailotp, emailotp: value });
  };

  // const handleChange = (index, e) => {
  //   let value = "";
  //   let a = 0;
  //   let stringLength = "";

  //   switch (index) {
  //     case 1:
  //       value = e.target.value;
  //       value = value.replace(/[^0-9]/, "");
  //       e.target.value = value;
  //       a = 0;
  //       stringLength = value.length;
  //       if (stringLength > a) {
  //         otp2.current.focus();
  //       }
  //       break;
  //     case 2:
  //       value = e.target.value;
  //       value = value.replace(/[^0-9]/, "");
  //       e.target.value = value;
  //       a = 0;
  //       stringLength = value.length;
  //       if (stringLength > a) {
  //         otp3.current.focus();
  //       }
  //       break;
  //     case 3:
  //       value = e.target.value;
  //       value = value.replace(/[^0-9]/, "");
  //       e.target.value = value;
  //       a = 0;
  //       stringLength = value.length;
  //       if (stringLength > a) {
  //         otp4.current.focus();
  //       }
  //       break;
  //     case 4:
  //       value = e.target.value;
  //       value = value.replace(/[^0-9]/, "");
  //       e.target.value = value;
  //       a = 0;
  //       stringLength = value.length;
  //       if (stringLength > a) {
  //         otp5.current.focus();
  //       }
  //       break;
  //     case 5:
  //       value = e.target.value;
  //       value = value.replace(/[^0-9]/, "");
  //       e.target.value = value;
  //       a = 0;
  //       stringLength = value.length;
  //       if (stringLength > a) {
  //         otp6.current.focus();
  //       }
  //       break;
  //     case 6:
  //       value = e.target.value;
  //       value = value.replace(/[^0-9]/, "");
  //       e.target.value = value;
  //       a = 0;
  //       stringLength = value.length;
  //       if (stringLength > a) {
  //         addOtp();
  //       }
  //       break;
  //   }
  // };


  const handleChange = (index, e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/, ""); // Only allow digits
    e.target.value = value;
  
    const prevRef = [otp1, otp2, otp3, otp4, otp5, otp6][index - 2]; // Reference to previous input
    const nextRef = [otp1, otp2, otp3, otp4, otp5, otp6][index]; // Reference to next input
    
    // If backspace is pressed and the input is empty, move to the previous input
    if (e.key === "Backspace" && value === "" && prevRef?.current) {
      prevRef.current.focus();
    } 
    // If the user entered a number, move to the next input
    else if (value.length > 0 && nextRef?.current) {
      nextRef.current.focus();
    }
  };
  


  const RegistrationemailOTPVerification=()=>{
    dispatch(OTPVerify(emailotp));
    if (emailOTPResponse?.statusCode === 200) {
    setRegistrationOtpOpen("")
    }
  }

  
  const [mobileotp, SetMobileotp] = useState({
    mobilenumber: "",
    mobileotp: "",
  });



  const RegistrationMobileNumberOTPVerification=()=>{
    dispatch(MobileOTPVerify(mobileotp));
    if (mobileOTPResponse?.statusCode === 200) {
      navigate("/dashboard");
    }

  }

  console.log(emailOTPResponse,"emailOTPResponse")
  return (
    <div className={Styles.RegistrationPageMainContainer}>
      {registrationOtpOpen === "Signup" ? (
        <div className={Styles.RegistrationContainer}>
          <img src={LoginImage} alt="" />
          <div className={Styles.RegistrationMainContent}>
            <p className={Styles.RegistrationMainContentRegistrationText}>
              Sign up
            </p>
            <div className={Styles.RegistrationNameContent}>
              <div className={Styles.RegistrationFirstNameContent}>
                <p className={Styles.RegistrationFirstNameLable}> First name</p>
                <TextField
                  id="outlined-start-adornment"
                  sx={{
                    width: "100%",
                    background: "#F8FAFC",
                    border: "1px solid #E9EDF2",
                    borderRadius: "8px",
                  }}
                  name="firstname"
                  placeholder={"Enter your first name"}
                  onChange={(e) =>
                    setRegistration({
                      ...registration,
                      firstname: e.target.value,
                    })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={Styles.RegistrationFirstNameContent}>
                <p className={Styles.RegistrationFirstNameLable}> Last name</p>
                <TextField
                  id="outlined-start-adornment"
                  sx={{
                    width: "100%",
                    background: "#F8FAFC",
                    border: "1px solid #E9EDF2",
                    borderRadius: "8px",
                  }}
                  name="lastName"
                  placeholder={"Enter your last name"}
                  onChange={(e) =>
                    setRegistration({ ...registration, lastName: e.target.value })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className={Styles.RegistrationEmailOrMobileContent}>
              <p className={Styles.RegistrationEmailOrMobileLable}>
                {" "}
                Email address{" "}
              </p>
              <TextField
                className={Styles.RegistrationPageEmailTextField}
                id="outlined-start-adornment"
                sx={{
                  width: "100%",
                  background: "#F8FAFC",
                  border: "1px solid #E9EDF2",
                  borderRadius: "8px",
                }}
                name="email"
                placeholder={"Enter your email or phone"}
                onChange={(e) =>
                  setRegistration({ ...registration, email: e.target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={Styles.RegistrationEmailOrMobileContent}>
              <p className={Styles.RegistrationEmailOrMobileLable}>
                {" "}
                Phone number{" "}
              </p>
              <MuiPhoneNumber
                defaultCountry={"in"}
                name="phonenumber"
                variant="outlined"
                required
                enableLongNumbers
                onChange={(value) =>
                  setRegistration({ ...registration, phonenumber: value })
                }
                sx={{
                  width: "100%",
                  background: "#F8FAFC",
                  border: "1px solid #E9EDF2",
                  borderRadius: "8px",
                }}
                placeholder={"Enter your Phone Number"}
                className={Styles.RegistrationPageMobileNumber}
              />
            </div>

            <div className={Styles.RegistrationEmailOrMobileContent}>
              <p className={Styles.RegistrationEmailOrMobileLable}> Password </p>
              <FormControl
                sx={{
                  width: "100%",
                  background: "#F8FAFC",
                  border: "1px solid #E9EDF2",
                  borderRadius: "8px",
                }}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder={"Enter your password"}
                  onChange={(e) =>
                    setRegistration({ ...registration, password: e.target.value })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </div>
            <div className={Styles.RegistrationEmailOrMobileContent}>
              <p className={Styles.RegistrationEmailOrMobileLable}>
                {" "}
                Confirm password{" "}
              </p>
              <FormControl
                sx={{
                  width: "100%",
                  background: "#F8FAFC",
                  border: "1px solid #E9EDF2",
                  borderRadius: "8px",
                }}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={confirmshowPassword ? "text" : "password"}
                  name="password"
                  placeholder={"Confirm password"}
                  onChange={(e) =>
                    setRegistration({ ...registration, password: e.target.value })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {confirmshowPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </div>
            <button
              className={Styles.RegistrationPageSubmitButton}
              onClick={() => createNewUser()}
            >
              Continue
            </button>
            <div className={Styles.RegistrationSignUpLinkContainer}>
            <p className={Styles.RegistrationSignUpLinkContainerText}>Already have an account?</p>
            <Link to="/">
              <p className={Styles.RegistrationSignUpLinkContainerTextLink}>
                Sign in
              </p>
            </Link>
          </div>  
          </div>
        </div> ): registrationOtpOpen === "emailOtp" ?(
        <div className={Styles.RegistrationOtpVerificationContanier}>
          <img src={LoginImage} alt="" />
          <div className={Styles.RegistrationOtpVerificationTitleContainer}>
            <p className={Styles.RegistrationOtpVerificationTitleText}>OTP Verification</p>
            <p className={Styles.RegistrationOtpVerificationTitleDescription}>
            We’ve sent a 6 digit code to {registration.email}
            </p>
            <div className={Styles.RegistrationOtpVerificationOTPContainer}>
              <input
                className={Styles.RegistrationOtpVerificationOTPInput}
                maxLength="1"
                onChange={(e) => handleChange(1, e)}
                onKeyDown={(e) => handleChange(1, e)} 
                ref={otp1}
              />
              <input
                className={Styles.RegistrationOtpVerificationOTPInput}
                maxLength="1"
                onChange={(e) => handleChange(2, e)}
                onKeyDown={(e) => handleChange(2, e)} 
                ref={otp2}
              />
              <input
                className={Styles.RegistrationOtpVerificationOTPInput}
                maxLength="1"
                onChange={(e) => handleChange(3, e)}
                onKeyDown={(e) => handleChange(3, e)} 
                ref={otp3}
              />
              <input
                className={Styles.RegistrationOtpVerificationOTPInput}
                maxLength="1"
                onChange={(e) => handleChange(4, e)}
                onKeyDown={(e) => handleChange(4, e)} 
                ref={otp4}
              />
              <input
                className={Styles.RegistrationOtpVerificationOTPInput}
                maxLength="1"
                onChange={(e) => handleChange(5, e)}
                onKeyDown={(e) => handleChange(5, e)} 
                ref={otp5}
              />
              <input
                className={Styles.RegistrationOtpVerificationOTPInput}
                maxLength="1"
                onChange={(e) => handleChange(6, e)}
                 onKeyDown={(e) => handleChange(6, e)} 
                ref={otp6}
              />
            </div>
          </div>
          <button
            className={Styles.RegistrationOtpVerificationemailOTPSubmitButton} onClick={()=> RegistrationemailOTPVerification()}>
            Continue
          </button>
          
        </div>):(
           <div className={Styles.RegistrationOtpVerificationContanier}>
           <img src={LoginImage} alt="" />
           <div className={Styles.RegistrationOtpVerificationTitleContainer}>
             <p className={Styles.RegistrationOtpVerificationTitleText}>OTP Verification</p>
             <p className={Styles.RegistrationOtpVerificationTitleDescription}>
             We’ve sent a 6 digit code to  {registration.phonenumber}
             </p>
             <div className={Styles.RegistrationOtpVerificationOTPContainer}>
               <input
                 className={Styles.RegistrationOtpVerificationOTPInput}
                 maxLength="1"
                 onChange={(e) => handleChange(1, e)}
                 onKeyDown={(e) => handleChange(1, e)} 
                 ref={otp1}
               />
               <input
                 className={Styles.RegistrationOtpVerificationOTPInput}
                 maxLength="1"
                 onChange={(e) => handleChange(2, e)}
                 onKeyDown={(e) => handleChange(2, e)} 
                 ref={otp2}
               />
               <input
                 className={Styles.RegistrationOtpVerificationOTPInput}
                 maxLength="1"
                 onChange={(e) => handleChange(3, e)}
                 onKeyDown={(e) => handleChange(3, e)} 
                 ref={otp3}
               />
               <input
                 className={Styles.RegistrationOtpVerificationOTPInput}
                 maxLength="1"
                 onChange={(e) => handleChange(4, e)}
                 onKeyDown={(e) => handleChange(4, e)} 
                 ref={otp4}
               />
               <input
                 className={Styles.RegistrationOtpVerificationOTPInput}
                 maxLength="1"
                 onChange={(e) => handleChange(5, e)}
                 onKeyDown={(e) => handleChange(5, e)} 
                 ref={otp5}
               />
               <input
                 className={Styles.RegistrationOtpVerificationOTPInput}
                 maxLength="1"
                 onChange={(e) => handleChange(6, e)}
                 onKeyDown={(e) => handleChange(6, e)} 
                 ref={otp6}
               />
             </div>
           </div>
           <button
             className={Styles.RegistrationOtpVerificationemailOTPSubmitButton} onClick={()=> RegistrationMobileNumberOTPVerification()}>
             Continue
           </button>
           
         </div>
        )
       }
    </div>
  );
};
export default Registration;
