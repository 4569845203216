import {
    GET_USER_DETAILS_RESPONSE,
  } from "./ActionTypes";
  
  const initialState = {
    error: "",
    getUserInformation: "",
  };
  
  const UserProfile = (user = initialState, action) => {
          switch (action.type) {
              case GET_USER_DETAILS_RESPONSE:
              user = {
                  ...user,
                  getUserInformation: action.payload,
              };
              break;
              default:
              user = { ...user };
              break;
          }
          return user;
  };
  
  export default UserProfile;
  