import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import  TableCell from '@mui/material/TableCell';
import  TableContainer from '@mui/material/TableContainer';
import  TableHead from '@mui/material/TableHead';
import  TableRow from '@mui/material/TableRow';
import  Paper from '@mui/material/Paper';
import './paymentHistory.css'
import Menu from"../../../assets/images/menuDotsIcon.png"
import Profile1 from '../../../assets/images/profileCoverDp.png'
import Rectangle from "../../../assets/images/rectangleShape.png"
import { TabsContext } from '@mui/base';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


function createData(name,id,status,amount,dollar,plan,date){
return{name,id,status,amount,dollar,plan,date};
}

const rows=[
  createData("Sydney Boyer","#39201","Failed","$41","USD","Splash","Sep 14, 11:56 PM"),
  createData("Todd Morar","#39201","Failed","$41","USD","Trickle","Sep 14, 11:56 PM"),
  createData("Clayton Hackett","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
  createData("Sydney Boyer","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
  createData("Todd Morar","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
  createData("Clayton Hackett","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
  createData("Sydney Boyer","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
  createData("Todd Morar","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
  createData("Sydney Boyer","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
  createData("Clayton Hackett","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
]


export default function UsersAndDevicesPage() {
  return (
    <>
        <div>
            <div className='paymentTitle'>
                <p>Payment history</p>
            </div>
            <div className='tableContainer' >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                    <TableCell className='tableHead'>  </TableCell>
                      <TableCell className='tableHead'>NAME</TableCell>
                      <TableCell className='tableHead'>REF ID</TableCell>
                      <TableCell className='tableHead'>STATUS</TableCell>
                      <TableCell className='tableHead'>AMOUNT</TableCell>
                      <TableCell className='tableHead'>CURRENT PLAN</TableCell>
                      <TableCell className='tableHead'>TRANSACTION DATE</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row)=>(
                      <>
                      <TableRow
                      key={row.name} className='bodyHead'
                      >
                        <TableCell  className='tableBody1' align="left"><img className="img1" src={Rectangle}/><img  className="img2" src={Profile1}/></TableCell>
                        <TableCell  className='tableBody' align="left">{row.name}</TableCell>
                        <TableCell className='tableBody' align="left">{row.id}</TableCell>
                        <TableCell className='tableBody' align="left"><span  style={{
                                color: row.status === 'Failed' ? '#A9281E' : row.status === 'Success' ? '#0D894F' : 'black', 
                                backgroundColor: row.status === 'Failed' ? '#FDEDEC' : row.status === 'Success' ? '#EAFBF0' : 'white',
                                width : 'fit-content',
                                borderRadius: '100px',
                                padding: '4px 12px', 
                               textAlign:'center',  

                                }}>{row.status}</span></TableCell>
                        <TableCell className='tableBody' align="left"><span>{row.amount}</span><span className='dollarColor'>{row.dollar}</span></TableCell>
                        <TableCell className='tableBodyClean' align="left">{row.plan}</TableCell>
                        <TableCell className='tableBody' align="left">{row.date}</TableCell>
                        <TableCell className='tableBodyLast'><img src={Menu}/></TableCell>
                      </TableRow>
                     <TableRow className='emptyRow'>
                      <TableCell className='emptyCell'></TableCell>
                      <TableCell className='emptyCell'></TableCell>
                      <TableCell className='emptyCell'></TableCell>
                      <TableCell className='emptyCell'></TableCell>
                      <TableCell className='emptyCell'></TableCell>
                      <TableCell className='emptyCell'></TableCell>
                      <TableCell className='emptyCell'></TableCell>
                     </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div>
            <Stack spacing={2}>
              <Pagination count={10} />
            </Stack>
            </div>
        </div>
        <hr className='line'></hr>
    </>
    
  )
}
