import React, { useEffect, useState } from "react";
import Styles from "./Index.module.css";
import { ReactComponent as MyTankFillIcon } from "../../../assets/SvgIcons/MyTankFillIcon.svg";
import { ReactComponent as MyTankEmptyIcon } from "../../../assets/SvgIcons/MyTankEmptyIcon.svg";
import { ReactComponent as MyTankOverFlowIcon } from "../../../assets/SvgIcons/MyTankOverFlowIcon.svg";
import { ReactComponent as LeftArrowIcon } from "../../../assets/SvgIcons/LeftArrowIcon.svg";
import { Link } from "react-router-dom";
import MenuIcon from "../../../assets/images/MenuIcon.png";
import WetflixLogo from "../../../assets/images/WetflixLogo.png";
import ProfileImage from "../../../assets/images/ProfileImage.png";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
// import NotificationsIcon from '@mui/icons-material/Notifications'; 
import { useDispatch, useSelector } from "react-redux";
import { getAllNotification , getDeviceApproved,getDeviceListBySerialNumber,getDeviceRejected} from "../../../Redux/Actions";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// import Notification from "../TestNotification/Notification";
import TextField from "@mui/material/TextField";
import { ReactComponent as AccountHumanIcon } from "../../../assets/SvgIcons/AccountHumanIcon.svg";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as LocationLocateIcon } from "../../../assets/SvgIcons/LocationLocateIcon.svg";
import { getUsetDetails ,} from "../../../Redux/Actions";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  bgcolor: 'background.paper',
  border: '1px solid #c8c5c5',
  boxShadow: 24,
  p: 4,
  borderRadius:5,
};

const Header = () => {
  const now = new Date();
  let dispatch = useDispatch();

  const formattedDate = now.toLocaleString("en-US", {
    month: "short",
    weekday: "long",
    day: "numeric",
  });
  const [isActive, setIsActive] = useState(false);

  const notificationList=useSelector((notification) => notification.Notification.getNotificationSuccessfull)

  const getAllUserDeviceListById = useSelector(
    (device) => device.DeviceList.getDeviceApproved
  );

  const [userid,setUserID]=useState(94)
  const [notificationLength, setNotificationLength]=useState()
  const [notificationListData, setNotificationListData]=useState()

  let loginResponse = useSelector((state) => state.UserProfile.getUserInformation);

  // let loginResponseData = useSelector((state) => state.Login.loginSuccessfull);

  // const [userIdData , setUserIdData] = useState(loginResponseData?.data?.userId)

  const authUserDataHeader = JSON.parse(sessionStorage.getItem("auth"));

  
  const [getAllUserDeviceList,setGetAllUserDeviceList]=useState()


  const OldNotificationCount = sessionStorage.getItem('NotificationCount',notificationListData?.length)

   useEffect(()=>{
    dispatch(getUsetDetails(authUserDataHeader.userId))

  },[])

  const TestVariable = notificationListData?.length

  useEffect(()=>{ 
      // handleOpeNotification()

    if (notificationList?.statusCode === 200  ) {
        setNotificationListData(notificationList.data)
        setNotificationLength(notificationList.data.length)
        setGetAllUserDeviceList(notificationList.data.slice(-1))
        
      } 
      if(OldNotificationCount == ""){
        // alert('come')
      }if(OldNotificationCount == notificationLength){
        // alert('data')  
      }if(OldNotificationCount < notificationLength){
        sessionStorage.setItem('NotificationCount',notificationLength)
        handleOpeNotification()
      }
      
},[ notificationList])

  

const [userDeviceSerialNum, setUserDeviceSerialNum]=useState("000127")


useEffect(()=>{
  dispatch(getDeviceListBySerialNumber(userDeviceSerialNum))
},[userDeviceSerialNum])


const [useDeviceApproved, setUseDeviceApproved]= useState(
  {
    userid: "96",
    deviceid:"30",
    geolocation:"13.0827° N, 80.2707° E",

  }
)

const [notificationopen, setNotificationopen] = useState(false);
const handleOpeNotification = () => {
  setNotificationopen(true);
}
const handleCloseNotification = () => setNotificationopen(false);

const DeviceApprovedData=()=>{
   dispatch(getDeviceApproved(useDeviceApproved));
   setNotificationopen(false);
  
}


const DeviceRejectyedData=()=>{
  dispatch(getDeviceRejected(useDeviceApproved));
  setNotificationopen(false);
}



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    sessionStorage.clear()
    setAnchorEl(null);
  };

useEffect(()=>{
    dispatch(getAllNotification(userid));
},[userid])

const [geoLocatrionData, setGeoLocatrionData]=useState("");
const GetGeoLocationBasedonUSer=()=>{
  if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(showPosition);
				} else { 
          setGeoLocatrionData("Geolocation is not supported by this browser.")
				}

				function showPosition(position) {
          setGeoLocatrionData(position.coords.latitude + "," +position.coords.longitude)
				}
}
  
  return (
    <>
      <div className={Styles.HeaderMainContainer}>
        <p className={Styles.HeaderWelComeText}>Welcome {loginResponse?.data?.firstname}</p>
        <div className={Styles.HeaderWelComeProfileContainer}>
          {/* */}
          <div>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img src={ProfileImage} alt=""  className={Styles.HeaderProfileImage}/>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}
               
               
                className={Styles.ProfileLink}>
                <Link  to="/profile" className={Styles.ProfileLink}>Profile</Link>
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={Link}
                reloadDocument
                to="/"
                className={Styles.ProfileLink}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
          <p
            className={Styles.HeaderWelComeProfileNotification}
            onClick={(e) => {
              setIsActive(!isActive);
            }}
          >
            <Badge variant="dot" color="error">
              <NotificationsNoneOutlinedIcon color="action" />
            </Badge>
          </p>
          <p className={Styles.HeaderWelComeProfileTimeAndDay}>
            {formattedDate}
          </p>
        </div>
         <div
          className={Styles.DropDownHeaderMainContainerContent}
          style={{ display: isActive ? "block" : "none" }}
        >
          <div
            className={Styles.DropDownHeaderMainContainer}
            onClick={(e) => {
              setIsActive(!isActive);
            }}
          >
            <p className={Styles.DropDownHeaderMainContainerHeader}>
              Alerts & notifications
            </p>
            <div className={Styles.HeaderPageNotificationCart}>
              <div className={Styles.HeaderPageNotificationDetails}>
                <div
                  className={Styles.HeaderPageNotificationDetailsHeaderContent}
                >
                  <p
                    className={
                      Styles.HeaderPageNotificationDetailsHeaderContentMyTank
                    }
                  >
                    My tank #1
                  </p>
                  <p
                    className={
                      Styles.HeaderPageNotificationDetailsHeaderContentMyTankLevel
                    }
                  >
                    is 97% full
                  </p>
                  <MyTankFillIcon />
                </div>
                <p className={Styles.HeaderPageNotificationDetailsDescription}>
                  Consectetur et et ornare sagittis iaculis a vestibulum.
                  Adipiscing nunc facilisis cursus gravida augue interdum.
                </p>
              </div>
              <p className={Styles.HeaderPageNotificationCartTime}>
                12:23 AM, 23-09-2023
              </p>
            </div>
            <div className={Styles.HeaderPageNotificationCart}>
              <div className={Styles.HeaderPageNotificationDetails}>
                <div
                  className={Styles.HeaderPageNotificationDetailsHeaderContent}
                >
                  <p
                    className={
                      Styles.HeaderPageNotificationDetailsHeaderContentMyTank
                    }
                  >
                    My tank #2
                  </p>
                  <p
                    className={
                      Styles.HeaderPageNotificationDetailsHeaderContentMyTankLevel
                    }
                  >
                    has gone below 5% level
                  </p>
                  <MyTankEmptyIcon />
                </div>
                <p className={Styles.HeaderPageNotificationDetailsDescription}>
                  Consectetur et et ornare sagittis iaculis a vestibulum
                </p>
              </div>
              <p className={Styles.HeaderPageNotificationCartTime}>
                12:23 AM, 23-09-2023
              </p>
            </div>
            <div className={Styles.HeaderPageNotificationCart}>
              <div className={Styles.HeaderPageNotificationDetails}>
                <div
                  className={Styles.HeaderPageNotificationDetailsHeaderContent}
                >
                  <p
                    className={
                      Styles.HeaderPageNotificationDetailsHeaderContentMyTank
                    }
                  >
                    My tank #2
                  </p>
                  <p
                    className={
                      Styles.HeaderPageNotificationDetailsHeaderContentMyTankLevel
                    }
                  >
                    had a water overflow
                  </p>
                  <MyTankOverFlowIcon />
                </div>
                <p className={Styles.HeaderPageNotificationDetailsDescription}>
                  Consectetur et et ornare sagittis iaculis a vestibulum.
                  Adipiscing nunc facilisis
                </p>
              </div>
              <p className={Styles.HeaderPageNotificationCartTime}>
                12:23 AM, 23-09-2023
              </p>
            </div>
            <Link
              to="/notification_component"
              className={Styles.HeaderViewLink}
            >
              <button className={Styles.HeaderPageNotificationCartButton}>
                <p className={Styles.HeaderPageNotificationCartButtonText}>
                  View all <LeftArrowIcon />
                </p>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className={Styles.MobileResponsiveHeaderContent}>
        <div className={Styles.MobileResponsiveHeaderContainer}>
          <img src={MenuIcon} alt="" />
          <img
            src={WetflixLogo}
            alt=""
            className={Styles.MobileResponsiveHeaderContainerImage}
          />
          <p
            className={Styles.HeaderWelComeProfileNotification}
            onClick={(e) => {
              setIsActive(!isActive);
            }}
          >
           <Badge variant="dot" color="error">
              <NotificationsNoneOutlinedIcon color="action" />
            </Badge>
          </p>
        </div>
        <div className={Styles.MobileResponsiveHeaderContainerUserNameContent}>
          <p className={Styles.MobileResponsiveHeaderContainerUserName}>
            Welcome Daniel
          </p>
        </div>
      </div>
     
      <Modal
        open={ notificationopen}
        onClose={handleCloseNotification}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={Styles.ModdelPageForDeviceApproved}>
            <p>Your new Device Waiting for your Conformation,</p>
                    <div className={Styles.ModelPageForGettingDeviceDetailsContainer}>
                      <div className={Styles.HeaderModelPageInputFieldContent}>
                        <p className={Styles.HeaderModelPageInputFieldContentText}>Device name</p>
                    <TextField
                      id="outlined-start-adornment"
                      sx={{
                        width: "100%",
                        background: "#F8FAFC",
                        border: "1px solid #E9EDF2",
                        borderRadius: "8px",
                        margin:"0.5rem 0rem"
                      }}

                      name="email"
                      placeholder={"Enter device name"}
                      // onChange={(e) => setLogin({ ...login, email: e.target.value })}
                      InputProps={{
                        // startAdornment: (
                        //   <InputAdornment position="start">
                        //     <MailIcon />
                        //   </InputAdornment>
                        // ),
                      }}
                    />
                    </div>
                    <div className={Styles.HeaderModelPageInputFieldContent}>
                        <p className={Styles.HeaderModelPageInputFieldContentText}>Last refill</p>
                    <TextField
                      id="outlined-start-adornment"
                      type="date"
                      sx={{
                        width: "100%",
                        background: "#F8FAFC",
                        border: "1px solid #E9EDF2",
                        borderRadius: "8px",
                        margin:"0.5rem 0rem"
                      }}

                      name="email"
                      // onChange={(e) => setLogin({ ...login, email: e.target.value })}
                      InputProps={{
                        // startAdornment: (
                        //   <InputAdornment position="start">
                        //     <MailIcon />
                        //   </InputAdornment>
                        // ),
                      }}
                    />
                    </div>
                    <div className={Styles.HeaderModelPageInputFieldContent}>
                        <p className={Styles.HeaderModelPageInputFieldContentText}>Last Tank Cleaning</p>
                    <TextField
                      id="outlined-start-adornment"
                      type="date"
                      sx={{
                        width: "100%",
                        background: "#F8FAFC",
                        border: "1px solid #E9EDF2",
                        borderRadius: "8px",
                        margin:"0.5rem 0rem" 
                      }}

                      name="email"
                      // onChange={(e) => setLogin({ ...login, email: e.target.value })}
                      InputProps={{
                        // startAdornment: (
                        //   <InputAdornment position="start">
                        //     <MailIcon />
                        //   </InputAdornment>
                        // ),
                      }}
                    />
                    </div>
                    <div className={Styles.HeaderModelPageInputFieldContent}>
                        <p className={Styles.HeaderModelPageInputFieldContentText}>Number of Tanks <span className={Styles.HeaderModelPageInputFieldContentTextImportent}>*</span></p>
                    <TextField
                      id="outlined-start-adornment"
                      sx={{
                        width: "100%",
                        background: "#F8FAFC",
                        border: "1px solid #E9EDF2",
                        borderRadius: "8px",
                        margin:"0.5rem 0rem"
                      }}

                      name="email"
                      placeholder={"This is an input field"}
                      // onChange={(e) => setLogin({ ...login, email: e.target.value })}
                      InputProps={{
                        // startAdornment: (
                        //   <InputAdornment position="start">
                        //     <MailIcon />
                        //   </InputAdornment>
                        // ),
                      }}
                    />
                    </div>
                    <div className={Styles.HeaderModelPageInputFieldContent}>
                        <p className={Styles.HeaderModelPageInputFieldContentText}>Installer name</p>
                    <TextField
                      id="outlined-start-adornment"
                      sx={{
                        width: "100%",
                        background: "#F8FAFC",
                        border: "1px solid #E9EDF2",
                        borderRadius: "8px",
                        margin:"0.5rem 0rem"
                      }}

                      name="email"
                      placeholder={"Enter a name"}
                      // onChange={(e) => setLogin({ ...login, email: e.target.value })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountHumanIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    </div>
                    <div className={Styles.HeaderModelPageInputFieldContent}>
                        <p className={Styles.HeaderModelPageInputFieldContentText}>Device location <span className={Styles.HeaderModelPageInputFieldContentTextImportent}>*</span></p>
                    <TextField
                      id="outlined-start-adornment"
                      sx={{
                        width: "100%",
                        background: "#F8FAFC",
                        border: "1px solid #E9EDF2",
                        borderRadius: "8px",
                        margin:"0.5rem 0rem"
                      }}
                      value={geoLocatrionData}
                      name="email"
                      placeholder={"13.0827°N, 80.219°E"}
                      // onChange={(e) => setLogin({ ...login, email: e.target.value })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LocationLocateIcon onClick={()=> GetGeoLocationBasedonUSer()} className={Styles.gettingGeoLocatoin}/>
                          </InputAdornment>
                        ),
                      }}
                    />
                    </div>
                    <div className={Styles.HeaderModelPageInputFieldContent}>
                        <p className={Styles.HeaderModelPageInputFieldContentText}>Device address <span className={Styles.HeaderModelPageInputFieldContentTextImportent}>*</span></p>
                    <TextField
                      id="outlined-start-adornment"
                      sx={{
                        width: "100%",
                        background: "#F8FAFC",
                        border: "1px solid #E9EDF2",
                        borderRadius: "8px",
                        margin:"0.5rem 0rem"
                      }}

                      name="email"
                      placeholder={"Enter Device Address"}
                      // onChange={(e) => setLogin({ ...login, email: e.target.value })}
                      InputProps={{
                        // startAdornment: (
                        //   <InputAdornment position="start">
                        //     <MailIcon />
                        //   </InputAdornment>
                        // ),
                      }}
                    />
                    </div>
                    <div className={Styles.HeaderModelPageInputFieldContent}>
                        <p className={Styles.HeaderModelPageInputFieldContentText}>Tank capacity <span className={Styles.HeaderModelPageInputFieldContentTextImportent}>*</span></p>
                    <TextField
                      id="outlined-start-adornment"
                      sx={{
                        width: "100%",
                        background: "#F8FAFC",
                        border: "1px solid #E9EDF2",
                        borderRadius: "8px",
                        margin:"0.5rem 0rem"
                      }}

                      name="email"
                      placeholder={"Enter Tank capacity"}
                      // onChange={(e) => setLogin({ ...login, email: e.target.value })}
                      InputProps={{
                        // startAdornment: (
                        //   <InputAdornment position="start">
                        //     <MailIcon />
                        //   </InputAdornment>
                        // ),
                      }}
                    />
                    </div>
                    <div className={Styles.HeaderModelPageInputFieldContent}>
                        <p className={Styles.HeaderModelPageInputFieldContentText}>Tank type</p>
                        <select className={Styles.usernameInputBox} name="DeviceTiming" id="DeviceTiming">
                        <option value="TypeOne">Type 1</option>
                        <option value="TypeTwo">Type 2</option>
                        <option value="TypeThree">Type 3</option>
                        <option value="TypeFour">Type 4</option>
                      </select>
                   </div>
            </div>
            <div className={Styles.ModdelPageForDeviceApprovedButtonContainer}>
              <button className={Styles.ModdelPageForDeviceApprovedButtonDeny} onClick={()=> DeviceRejectyedData()}>Deny</button>
              <button className={Styles.ModdelPageForDeviceApprovedButton} onClick={()=> DeviceApprovedData()}>Accept</button>

            </div>
        </Box>
      </Modal> 
      {/* <Notification/> */}
    </>
  );
};
export default Header;
