import React, { useEffect, useRef } from 'react';
import MarkerIcon from "../../../../assets/images/MapIcon1.png";
import "./mapandgeolocation.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllMaps } from '../../../../Redux/Actions';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 13.08784000,
  lng: 80.27847000  
};

const markers = [
  { id: 1, position: { lat: 13.08784000, lng: 80.27847000 }, icon: MarkerIcon },
  { id: 2, position: { lat: 12.97194000, lng: 77.59369000 }, icon: MarkerIcon },
  { id: 3, position: { lat: 10.85051590, lng: 76.27108330 }, icon: MarkerIcon }
];

function MapAndGeolocation() {
  const dispatch = useDispatch();
  const heatList = useSelector((heatdata) => heatdata.Map.mapResponse);

  useEffect(() => {
    dispatch(getAllMaps()); 
  }, [dispatch]);

  const mapRef = useRef(null);

  useEffect(() => {
    if (window.google && mapRef.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: center,
        zoom: 10
      });

      heatList.data?.forEach((card) => {
        // Extract latitude and longitude from devicelocation
        const [latStr, lngStr] = card.devicelocation.split(', ');
        const lat = parseFloat(latStr);
        const lng = parseFloat(lngStr);

        if (!isNaN(lat) && !isNaN(lng)) { // Ensure lat and lng are valid numbers
          const marker = new window.google.maps.Marker({
            position: { lat, lng },
            map: map,
            icon: {
              url: MarkerIcon,
              scaledSize: new window.google.maps.Size(50, 50)
            }
          });
          

          const contentString = `
            <div style="width:248px;gap: 8px;">
              <h3 style="width: 176px;height: 18px;font-size: 18px;font-weight: 800;line-height: 18px;text-align: left;color:#181C20">${card.devicename}</h3>
              <p style="font-family: Figtree;font-size: 10px;font-weight: 400;line-height: 16px;text-align: left;color: #6A7A8A;">${card.createdtime}</p>
              <div style="display:flex; justify-content: space-between; ">
                <div style="width:85px;height:24px;padding: 4px 12px 4px 12px;gap: 8px;border-radius: 100px;opacity: 0px;background: #EAFBF0;"><p style="width: 61px;height: 16px;gap: 0px;opacity: 0px;font-size: 12px;font-weight: 400;line-height: 16px;text-align: center;color: #147138;">${card.deviceStatus}</p></div>
                <div style="width:106px;height:36px;gap: 4px;opacity: 0px;margin-top:-0.5rem">
                  <p style="width: 106px;height: 16px;width: 106px;height: 16px;;color:#6A7A8A;">Last updated</p>
                  <p style="width: 93px;height: 16px;gap: 0px;opacity: 0px;color:#181C20;margin-top:-0.5rem">${card.createdtime}</p>
                </div>
              </div>
              <hr style="margin-top:1rem">
              <div style="display:flex; justify-content: space-between;margin-top:-1rem ">
                <p style="width: 141px;height: 16px;font-size: 12px;font-weight: 400;line-height: 16px;text-align: left;color:#6A7A8A">Water level</p>
                <p style="font-family: Figtree;font-size: 12px;font-weight: 400;line-height: 16px;text-align: left;width: 105px;height: 16px;gap: 0px;opacity: 0px;color:#181C20">${card.waterlevel}</p>
              </div>
              <div style="display:flex; justify-content: space-between;margin-top:-0.5rem  ">
                <p style="width: 151px;height: 16px;gap: 0px;opacity: 0px;width: 171px;height: 16px;gap: 0px;opacity: 0px;color:#6A7A8A">Next cleaning date</p>
                <p style="width: 109px;height: 16px;gap: 0px;opacity: 0px;font-family: Figtree;font-size: 12px;font-weight: 400;line-height: 16px;text-align: left;color:#181C20">${card.nextmaintence}</p>
              </div>
            </div>
          `;

          const infowindow = new window.google.maps.InfoWindow({
            content: contentString
          });

          marker.addListener('click', () => {
            infowindow.open(map, marker); 
          });
        }
      });
    }
  }, [heatList]);

  return (
    <>
      <div style={containerStyle} ref={mapRef} />
      
    </>
  );
}

export default MapAndGeolocation;
