// import React, { useRef, useState } from "react";
// import Styles from "./Index.module.css";
// import LoginImage from "../../../assets/images/LoginImage.png";
// import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment";
// import { ReactComponent as MailIcon } from "../../../assets/SvgIcons/MailIcon.svg";
// import {
//   changePassword,
//   OTPVerify,
//   forgotpassword,
// } from "../../../Redux/Actions";
// import { useDispatch, useSelector } from "react-redux";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import FormControl from "@mui/material/FormControl";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import IconButton from "@mui/material/IconButton";
// import { Link, useNavigate } from "react-router-dom";

// const ForgotPassword = () => {
//   let dispatch = useDispatch();
//   const navigate = useNavigate();

//   const [forgotPassword, setForgotPassword] = useState({
//     email: "",
//   });

//   let forgotPasswordResponse = useSelector(
//     (state) => state.Login.forgotpassword
//   );
//   let emailOTPResponse = useSelector((state) => state.OTPVerification.OTPvalidation);
//   let resetPassword = useSelector((state) => state.Login.changePassword);

//   const [open, setOpen] = useState("forgotpassword");

//   const ForgotPasswordLink = () => {
//     dispatch(forgotpassword(forgotPassword));
//     if (forgotPasswordResponse?.statusCode === 200) {
//       setOpen("otp");
//     }
//   };

//   //otp
//   const [emailotp, SetEmailotp] = useState({
//     email: "",
//     emailotp: "",
//   });

//   const otp1 = useRef();
//   const otp2 = useRef();
//   const otp3 = useRef();
//   const otp4 = useRef();
//   const otp5 = useRef();
//   const otp6 = useRef();

//   const addOtp = () => {
//     let value = 0;
//     value =
//       otp1.current.value +
//       otp2.current.value +
//       otp3.current.value +
//       otp4.current.value +
//       otp5.current.value +
//       otp6.current.value;
//     SetEmailotp({ ...emailotp, emailotp: value });
//   };

//   const handleChange = (index, e) => {
//     let value = "";
//     let a = 0;
//     let stringLength = "";

//     switch (index) {
//       case 1:
//         value = e.target.value;
//         value = value.replace(/[^0-9]/, "");
//         e.target.value = value;
//         a = 0;
//         stringLength = value.length;
//         if (stringLength > a) {
//           otp2.current.focus();
//         }
//         break;
//       case 2:
//         value = e.target.value;
//         value = value.replace(/[^0-9]/, "");
//         e.target.value = value;
//         a = 0;
//         stringLength = value.length;
//         if (stringLength > a) {
//           otp3.current.focus();
//         }
//         break;
//       case 3:
//         value = e.target.value;
//         value = value.replace(/[^0-9]/, "");
//         e.target.value = value;
//         a = 0;
//         stringLength = value.length;
//         if (stringLength > a) {
//           otp4.current.focus();
//         }
//         break;
//       case 4:
//         value = e.target.value;
//         value = value.replace(/[^0-9]/, "");
//         e.target.value = value;
//         a = 0;
//         stringLength = value.length;
//         if (stringLength > a) {
//           otp5.current.focus();
//         }
//         break;
//       case 5:
//         value = e.target.value;
//         value = value.replace(/[^0-9]/, "");
//         e.target.value = value;
//         a = 0;
//         stringLength = value.length;
//         if (stringLength > a) {
//           otp6.current.focus();
//         }
//         break;
//       case 6:
//         value = e.target.value;
//         value = value.replace(/[^0-9]/, "");
//         e.target.value = value;
//         a = 0;
//         stringLength = value.length;
//         if (stringLength > a) {
//           addOtp();
//         }
//         break;
//     }
//   };

//   const emailOTPValidation = () => {
//     dispatch(OTPVerify(emailotp));
//     if (emailOTPResponse?.statusCode === 200) {
//       setOpen("");
//     }
//   };

//   //changePassword

//   const [passwordUpdate, setPasswordUpdate] = useState({
//     email: "",
//     password: "",
//     confirmpassword: "",
//   });
//   const [showPassword, setShowPassword] = useState(false);

//   const handleClickShowPassword = () => setShowPassword((show) => !show);

//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   const [confirmshowPassword, setConfirmShowPassword] = useState(false);

//   const handleClickShowConfirmPassword = () =>
//     setConfirmShowPassword((show) => !show);

//   const handleMouseDownConfirmPassword = (event) => {
//     event.preventDefault();
//   };

//   const changePaswordUser = () => {
//     dispatch(changePassword(passwordUpdate));
//     if (resetPassword?.statusCode === 200) {
//       navigate("/");
//     }
//   };

//   return (
//     <div className={Styles.ForgotPasswordMainContainer}>
//       <img src={LoginImage} alt="" />
//       {open === "forgotpassword" ? (
//         <div className={Styles.ForgotPasswordContant}>
//           <div className={Styles.ForgotPasswordTitleContant}>
//             <p className={Styles.ForgotPasswordContantTitleText}>
//               Forgot your password?
//             </p>
//             <p className={Styles.ForgotPasswordContantTitleDescription}>
//               We’ll send a link that will sign you into the app instantly.
//             </p>
//           </div>
//           <div className={Styles.ForgotPasswordEmailOrMobileContent}>
//             <p className={Styles.ForgotPasswordEmailOrMobileLable}>
//               {" "}
//               Email address / Phone number{" "}
//             </p>
//             <TextField
//               id="outlined-start-adornment"
//               sx={{
//                 width: "100%",
//                 background: "#F8FAFC",
//                 border: "1px solid #E9EDF2",
//                 borderRadius: "8px",
//               }}
//               name="email"
//               placeholder={"Enter your email or phone"}
//               onChange={(e) =>
//                 setForgotPassword({ ...forgotPassword, email: e.target.value })
//               }
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <MailIcon />
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </div>
//           <button
//             className={Styles.ForgotPasswordSubmitButton}
//             onClick={() => {
//               ForgotPasswordLink();
//               SetEmailotp({ ...emailotp, email: forgotPassword.email });
//             }}
//           >
//             Send
//           </button>
//           <div className={Styles.ForgotPasswordResendOTPLinkContent}>
//             <p className={Styles.ForgotPasswordResendOTPText}>
//               Didn’t receive the link?
//             </p>
//             <p className={Styles.ForgotPasswordResendOTPTLink}>
//               Click to resend
//             </p>
//           </div>
//         </div>
//       ) : open === "otp" ? (
//         <div className={Styles.ForgotPasswordContant}>
//           <div className={Styles.ResetPasswordTitleContainer}>
//             <p className={Styles.ResetPasswordTitleText}>Password reset</p>
//             <p className={Styles.ResetPasswordTitleDescription}>
//               We’ve sent a 6 digit code to {forgotPassword.email}
//             </p>
//             <div className={Styles.ResetPasswordOTPContainer}>
//               <input
//                 className={Styles.ForgotPasswordOTPInput}
//                 maxLength="1"
//                 onChange={(e) => handleChange(1, e)}
//                 ref={otp1}
//               />
//               <input
//                 className={Styles.ForgotPasswordOTPInput}
//                 maxLength="1"
//                 onChange={(e) => handleChange(2, e)}
//                 ref={otp2}
//               />
//               <input
//                 className={Styles.ForgotPasswordOTPInput}
//                 maxLength="1"
//                 onChange={(e) => handleChange(3, e)}
//                 ref={otp3}
//               />
//               <input
//                 className={Styles.ForgotPasswordOTPInput}
//                 maxLength="1"
//                 onChange={(e) => handleChange(4, e)}
//                 ref={otp4}
//               />
//               <input
//                 className={Styles.ForgotPasswordOTPInput}
//                 maxLength="1"
//                 onChange={(e) => handleChange(5, e)}
//                 ref={otp5}
//               />
//               <input
//                 className={Styles.ForgotPasswordOTPInput}
//                 maxLength="1"
//                 onChange={(e) => handleChange(6, e)}
//                 ref={otp6}
//               />
//             </div>
//           </div>
//           <button
//             className={Styles.emailOTPSubmitButton}
//             onClick={() => {
//               emailOTPValidation();
//               setPasswordUpdate({
//                 ...passwordUpdate,
//                 email: forgotPassword.email,
//               });
//             }}
//           >
//             Continue
//           </button>
//         </div>
//       ) : (
//         <div className={Styles.ChangePasswordContant}>
//           <div className={Styles.ForgotPasswordTitleContant}>
//             <p className={Styles.ForgotPasswordContantTitleText}>
//               Create a password
//             </p>
//             <p className={Styles.ForgotPasswordContantTitleDescription}>
//               Must be atleast 8 characters
//             </p>
//           </div>
//           <div className={Styles.changePasswordContent}>
//             <p className={Styles.changePasswordLable}> Password </p>
//             <FormControl
//               sx={{
//                 width: "100%",
//                 background: "#F8FAFC",
//                 border: "1px solid #E9EDF2",
//                 borderRadius: "8px",
//               }}
//               variant="outlined"
//             >
//               <OutlinedInput
//                 id="outlined-adornment-password"
//                 type={showPassword ? "text" : "password"}
//                 name="password"
//                 placeholder={"Enter your password"}
//                 onChange={(e) =>
//                   setPasswordUpdate({
//                     ...passwordUpdate,
//                     password: e.target.value,
//                   })
//                 }
//                 endAdornment={
//                   <InputAdornment position="end">
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={handleClickShowPassword}
//                       onMouseDown={handleMouseDownPassword}
//                       edge="end"
//                     >
//                       {showPassword ? <VisibilityOff /> : <Visibility />}
//                     </IconButton>
//                   </InputAdornment>
//                 }
//                 label="Password"
//               />
//             </FormControl>
//           </div>
//           <div className={Styles.changePasswordContent}>
//             <p className={Styles.changePasswordLable}> Confirm password </p>
//             <FormControl
//               sx={{
//                 width: "100%",
//                 background: "#F8FAFC",
//                 border: "1px solid #E9EDF2",
//                 borderRadius: "8px",
//               }}
//               variant="outlined"
//             >
//               <OutlinedInput
//                 id="outlined-adornment-password"
//                 type={confirmshowPassword ? "text" : "password"}
//                 name="confirmpassword"
//                 placeholder={"Confirm password"}
//                 onChange={(e) =>
//                   setPasswordUpdate({
//                     ...passwordUpdate,
//                     confirmpassword: e.target.value,
//                   })
//                 }
//                 endAdornment={
//                   <InputAdornment position="end">
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={handleClickShowConfirmPassword}
//                       onMouseDown={handleMouseDownConfirmPassword}
//                       edge="end"
//                     >
//                       {confirmshowPassword ? <VisibilityOff /> : <Visibility />}
//                     </IconButton>
//                   </InputAdornment>
//                 }
//                 label="Password"
//               />
//             </FormControl>
//           </div>
//           <button
//             className={Styles.ForgotPasswordSubmitButton}
//             onClick={() => changePaswordUser()}
//           >
//             Confirm
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };
// export default ForgotPassword;

























import React, { useRef, useState } from "react";
import Styles from "./Index.module.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as MailIcon } from "../../../assets/SvgIcons/MailIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import LoginImage from "../../../assets/images/LoginImage.png";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from "react-router-dom";
import {
  changePassword,
  OTPVerify,
  forgotpassword,
} from "../../../Redux/Actions";

const ForgotPassword = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const [forgotPassword, setForgotPassword] = useState({ email: "" });
  let forgotPasswordResponse = useSelector((state) => state.Login.forgotpassword);
  let emailOTPResponse = useSelector((state) => state.OTPVerification.OTPvalidation);
  let resetPassword = useSelector((state) => state.Login.changePassword);

  const [open, setOpen] = useState("forgotpassword");

  const ForgotPasswordLink = () => {
    dispatch(forgotpassword(forgotPassword));
    if (forgotPasswordResponse?.statusCode === 200) {
      setOpen("otp");
    }
  };

  const [emailotp, SetEmailotp] = useState({ email: "", emailotp: "" });

  const otpRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef()
  ];

  const addOtp = () => {
    const otpValue = otpRefs.map(ref => ref.current.value).join('');
    SetEmailotp({ ...emailotp, emailotp: otpValue });
  };

  const handleChange = (index, e) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
    e.target.value = value;

    // Move focus to next input
    if (value.length > 0 && index < otpRefs.length - 1) {
      otpRefs[index + 1].current.focus();
    } else if (index === otpRefs.length - 1) {
      addOtp();
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpRefs[index - 1].current.focus();
      otpRefs[index - 1].current.value = ''; // Clear previous input if backspace is pressed
    }
  };

  const emailOTPValidation = () => {
    dispatch(OTPVerify(emailotp));
    if (emailOTPResponse?.statusCode === 200) {
      setOpen("");
    }
  };

  const [passwordUpdate, setPasswordUpdate] = useState({
    email: "",
    password: "",
    confirmpassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [confirmshowPassword, setConfirmShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const handleClickShowConfirmPassword = () => setConfirmShowPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => event.preventDefault();

  const changePaswordUser = () => {
    dispatch(changePassword(passwordUpdate));
    if (resetPassword?.statusCode === 200) {
      navigate("/");
    }
  };

  return (
    <div className={Styles.ForgotPasswordMainContainer}>
      <img src={LoginImage} alt="" />
      {open === "forgotpassword" ? (
        <div className={Styles.ForgotPasswordContant}>
          <div className={Styles.ForgotPasswordTitleContant}>
            <p className={Styles.ForgotPasswordContantTitleText}>
              Forgot your password?
            </p>
            <p className={Styles.ForgotPasswordContantTitleDescription}>
              We’ll send a link that will sign you into the app instantly.
            </p>
          </div>
          <div className={Styles.ForgotPasswordEmailOrMobileContent}>
            <p className={Styles.ForgotPasswordEmailOrMobileLable}>
              Email address / Phone number
            </p>
            <TextField
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                background: "#F8FAFC",
                border: "1px solid #E9EDF2",
                borderRadius: "8px",
              }}
              name="email"
              placeholder={"Enter your email or phone"}
              onChange={(e) =>
                setForgotPassword({ ...forgotPassword, email: e.target.value })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <button
            className={Styles.ForgotPasswordSubmitButton}
            onClick={() => {
              ForgotPasswordLink();
              SetEmailotp({ ...emailotp, email: forgotPassword.email });
            }}
          >
            Send
          </button>
          <div className={Styles.ForgotPasswordResendOTPLinkContent}>
            <p className={Styles.ForgotPasswordResendOTPText}>
              Didn’t receive the link?
            </p>
            <p className={Styles.ForgotPasswordResendOTPTLink}>
              Click to resend
            </p>
          </div>
        </div>
      ) : open === "otp" ? (
        <div className={Styles.ForgotPasswordContant}>
          <div className={Styles.ResetPasswordTitleContainer}>
            <p className={Styles.ResetPasswordTitleText}>Password reset</p>
            <p className={Styles.ResetPasswordTitleDescription}>
              We’ve sent a 6 digit code to {forgotPassword.email}
            </p>
            <div className={Styles.ResetPasswordOTPContainer}>
              {otpRefs.map((ref, index) => (
                <input
                  key={index}
                  className={Styles.ForgotPasswordOTPInput}
                  maxLength="1"
                  onChange={(e) => handleChange(index, e)}
                  onKeyDown={(e) => handleBackspace(index, e)}
                  ref={ref}
                />
              ))}
            </div>
          </div>
          <button
            className={Styles.emailOTPSubmitButton}
            onClick={() => {
              emailOTPValidation();
              setPasswordUpdate({
                ...passwordUpdate,
                email: forgotPassword.email,
              });
            }}
          >
            Continue
          </button>
        </div>
      ) : (
        <div className={Styles.ChangePasswordContant}>
          <div className={Styles.ForgotPasswordTitleContant}>
            <p className={Styles.ForgotPasswordContantTitleText}>
              Create a password
            </p>
            <p className={Styles.ForgotPasswordContantTitleDescription}>
              Must be atleast 8 characters
            </p>
          </div>
          <div className={Styles.changePasswordContent}>
            <p className={Styles.changePasswordLable}> Password </p>
            <FormControl
              sx={{
                width: "100%",
                background: "#F8FAFC",
                border: "1px solid #E9EDF2",
                borderRadius: "8px",
              }}
              variant="outlined"
            >
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder={"Enter your password"}
                onChange={(e) =>
                  setPasswordUpdate({
                    ...passwordUpdate,
                    password: e.target.value,
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </div>
          <div className={Styles.changePasswordContent}>
            <p className={Styles.changePasswordLable}> Confirm password </p>
            <FormControl
              sx={{
                width: "100%",
                background: "#F8FAFC",
                border: "1px solid #E9EDF2",
                borderRadius: "8px",
              }}
              variant="outlined"
            >
              <OutlinedInput
                id="outlined-adornment-password"
                type={confirmshowPassword ? "text" : "password"}
                name="confirmpassword"
                placeholder={"Confirm password"}
                onChange={(e) =>
                  setPasswordUpdate({
                    ...passwordUpdate,
                    confirmpassword: e.target.value,
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {confirmshowPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </div>
          <button
            className={Styles.ForgotPasswordSubmitButton}
            onClick={() => changePaswordUser()}
          >
            Confirm
          </button>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
