import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {  GET_USER_DETAILS } from "./ActionTypes";
import { getUsetDetailsResponse } from "./Action";
import { Service } from "../../screens/Services/Helper.js"

function* getUserProfile({ payload: getUserDetailsInfo }) {
  try {
    const response = yield call(
      Service.commonFetch,
      `/userauth/getUserById?userid=${getUserDetailsInfo}`,
      "GET",
      null,
      true,

    );
    yield put(getUsetDetailsResponse(response));
    console.log(response)
  } catch (error) {

  }
}

function* UserProfile() {
  yield takeEvery(GET_USER_DETAILS, getUserProfile);
 
}

export default UserProfile;
