export const CREATE_BOOKING = "CREATE_BOOKING"
export const CREATE_BOOKING_RESPONSE = "CREATE_BOOKING_RESPONSE"
export const GET_ALL_BOOKING = "GET_ALL_BOOKING"
export const GET_ALL_BOOKING_RESPONSE = "GET_ALL_BOOKING_RESPONSE"
export const GET_ALL_UPCOMMING = "GET_ALL_UPCOMMING"
export const GET_ALL_UPCOMMING_RESPONSE = "GET_ALL_UPCOMMING_RESPONSE"
export const GET_ALL_INPROGRESS = "GET_ALL_INPROGRESS"
export const GET_ALL_INPROGRESS_RESPONSE = "GET_ALL_INPROGRESS_RESPONSE"
export const GET_ALL_SUCCESSFULL = "GET_ALL_SUCCESSFULL"
export const GET_ALL_SUCCESSFULL_RESPONSE = "GET_ALL_SUCCESSFULL_RESPONSE"
export const GET_BOOKING_STATUS_UPDATE = "GET_BOOKING_STATUS_UPDATE"
export const GET_BOOKING_STATUS_UPDATE_RESPONSE = "GET_BOOKING_STATUS_UPDATE_RESPONSE"
export const GET_ADMIN_BOOKING = "GET_ADMIN_BOOKING"
export const GET_ADMIN_BOOKING_RESPONSE = "GET_ADMIN_BOOKING_RESPONSE"
export const GET_ADMIN_CREATE_BOOKING = "GET_ADMIN_CREATE_BOOKING"
export const GET_ADMIN_CREATE_BOOKING_RESPONSE = "GET_ADMIN_CREATE_BOOKING_RESPONSE"
export const GET_ADMIN_INPROGRESS_BOOKING = "GET_ADMIN_INPROGRESS_BOOKING"
export const GET_ADMIN_INPROGRESS_BOOKING_RESPONSE = "GET_ADMIN_INPROGRESS_BOOKING_RESPONSE"
export const GET_ADMIN_CLOSE_BOOKING = "GET_ADMIN_CLOSE_BOOKING"
export const GET_ADMIN_CLOSE_BOOKING_RESPONSE = "GET_ADMIN_CLOSE_BOOKING_RESPONSE"