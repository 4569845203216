// import React, { useEffect, useState } from 'react'
// import './InprogressTable.scss'
// import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
// import {ReactComponent as MyBookingsTableRightIcon} from '../../../../assets/SvgIcons/MyBookingsTableRightIcon.svg'
// import Rectangle from '../../../../assets/images/Rectangle.png'
// import { Link } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { getAllInprogerss,  } from "../../../../Redux/Actions";


// const upComingStyles ={
//      marginLeft:'20px',
//      padding:'8px 13px',
//      borderRadius: '40px',
//      backgroundColor: '#FEF9F5',
//      justifyContent: 'center',
//      alignItems: 'center',
//      color: '#FF824D',
//     fontSize: '14px',
//     fontWeight: '500',
// }


// const InprogressTable = () => {
//     const dispatch = useDispatch();

//     const getAllInProgressDetails = useSelector(booking => booking.Booking.getAllInProgressDetails)



//     const [userUpCommingID, setUserUpCommingID]=useState({
//         bookingstatus:"CANCELLED",
//         userid:"94"
//     })

//     useEffect(() => {
//          dispatch(getAllInprogerss(userUpCommingID)); 
//     }, [userUpCommingID]);

   

//   return (
//     <div className='MyBookingsTable'>
//         <div className='Bottom'>
//             <table>
//                 <thead>
//                     <tr className='TableHead'>
//                         <th className="TableBody"></th>
//                         <th  className="Bookingdetails">BOOKING</th>
//                         <th  className="Bookingdetails">Technician name</th>
//                         <th  className="Bookingdetails">BOOKING DATE</th>
//                         <th className="Bookingdetails">REF ID</th>
//                         <th className="Bookingdetails "></th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                 {getAllInProgressDetails?.data?.map((data)=> (
//                     <tr className='TableBody' key={data.id}>
//                         <td><img src={Rectangle} alt='indication' /></td>
//                         <td className='Bookingdetails'>{data.description}</td>
//                         <td className='techniciandetails'>John</td>
//                         <td className='BookingDate'> {new Date(`${data.bookingdatetime}`).toLocaleDateString("en-US",{ year: 'numeric', month: 'short', day: 'numeric' })} 
//                         <div
//                                 style={{
//                                 color: data.bookingstatus === 'PENDING' ? '#E46A11' : data.bookingstatus === 'TECHASSIGNED' ? '#0D894F' : '#545F71', 
//                                 backgroundColor: data.bookingstatus === 'PENDING' ? '#FDF1E8' : data.bookingstatus === 'TECHASSIGNED' ? '#E7F4EE' : '#EEF1F4',
//                                 width : 'fit-content',
//                                 borderRadius: '100px',
//                                 padding: '4px 12px', 
//                                textAlign:'center',
//                                margin:'0rem 0.5rem'

//                                 }}
//                             ><div>{data.bookingstatus}</div></div>
//                          </td>
//                         <td className='Bookingdetails'>{data._id}</td>
//                         <td style={{display:'flex', alignItems:'start'}}><div style={{color:'#4D8BFF'}}><Link to="/booking_details" className="LinkedBookingDetails">View booking</Link></div> <div><MyBookingsTableRightIcon/></div> </td>
//                     </tr>
//                 ))}
//                 </tbody>
//                 </table>
//         </div>
//     </div>
//   )
// }

// export default InprogressTable





















import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import Styles from './InprogressTable.scss';
import { ReactComponent as MyBookingsTableRightIcon } from '../../../../assets/SvgIcons/MyBookingsTableRightIcon.svg';
import Rectangle from '../../../../assets/images/Rectangle.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllInprogerss } from '../../../../Redux/Actions';

const InprogressTable = () => {
    const dispatch = useDispatch();
    const getAllInProgressDetails = useSelector(booking => booking.Booking.getAllInProgressDetails);

    const [userInprogressID, setUserInprogressID] = useState({
        bookingstatus: "INPROGRESS",
        userid: "94"
    });

    useEffect(() => {
        dispatch(getAllInprogerss(userInprogressID));
    }, [userInprogressID, dispatch]);

    // Prepare data for MDBDataTable
    const data = {
        columns: [
            { label: 'Booking', field: 'description', sort: 'asc', width: 200 },
            { label: 'Technician Name', field: 'technicianName', sort: 'asc', width: 200 },
            { label: 'Booking Date', field: 'bookingDate', sort: 'asc', width: 200 },
            { label: 'Ref ID', field: 'refId', sort: 'asc', width: 100 },
            { label: 'Status', field: 'status', sort: 'asc', width: 150 },
            { label: 'Action', field: 'action', sort: 'asc', width: 150 }
        ],
        rows: getAllInProgressDetails?.data?.map((data) => ({
            description: data.description,
            technicianName: 'John', // Replace with actual technician name
            bookingDate: new Date(data.bookingdatetime).toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }),
            refId: data._id,
            status: (
                <div
                    style={{
                        color: data.bookingstatus === 'PENDING' ? '#E46A11' : data.bookingstatus === 'TECHASSIGNED' ? '#0D894F' : '#545F71',
                        backgroundColor: data.bookingstatus === 'PENDING' ? '#FDF1E8' : data.bookingstatus === 'TECHASSIGNED' ? '#E7F4EE' : '#EEF1F4',
                        borderRadius: '100px',
                        padding: '4px 12px',
                        textAlign: 'center',
                        margin: '0 0.5rem'
                    }}
                >
                    {data.bookingstatus}
                </div>
            ),
            action: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link to="/booking_details" className="LinkedBookingDetails" style={{ color: '#4D8BFF' }}>View booking</Link>
                    <MyBookingsTableRightIcon />
                </div>
            )
        }))
    };

    return (
        <div className={Styles.activeTable}>
            <MDBDataTable
                responsive
                data={data}
                entries={10}
                noBottomColumns
            />
        </div>
    );
}

export default InprogressTable;
