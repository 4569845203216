import React, { useEffect ,useRef} from "react";
import Styles from "./Index.module.css"
import PaymentWaySuccess from "../../../assets/images/PaymentWaySuccess.png";
import TricleImage from "../../../assets/images/TricleImage.png";
import SplashImage from "../../../assets/images/SplashImage.png";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from "react";
import SuccessImage from "../../../assets/images/SuccessImage.png"
import { useDispatch, useSelector } from "react-redux";
import { addSubscription,getPayment } from "../../../Redux/Actions";
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";

const CustomToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#4d8bff",
      textTransform: "capitalize",
    },
  });

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    bgcolor: 'background.paper',
    border: 'px solid  #ECF0F4',
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };


  const STATUS = {
    STARTED: 'Started',
    STOPPED: 'Stopped',
  }
  
  const INITIAL_COUNT = 30

  
  function useInterval(callback, delay) {
      const savedCallback = useRef()
    
      useEffect(() => {
        savedCallback.current = callback
      }, [callback])
    
      useEffect(() => {
        function tick() {
          savedCallback.current()
        }
        if (delay !== null) {
          let id = setInterval(tick, delay)
          return () => clearInterval(id)
        }
      }, [delay])
    }
    
    const twoDigits = (num) => String(num).padStart(2, '0')
  




const ChangePlan=()=>{  

    let dispatch = useDispatch();

    const getPaymentResponse = useSelector(
        (payment) => payment.GetPayment.paymentdetails
      );

    const [open, setOpen] = useState(false);



    const dtStr = new Date().getTime();
    console.log(dtStr, "time ")


    const [subscription, setSubscription]=useState("Drip");
    // const [addSubscriptionData, setAddSubscriptionData] = useState({
    //     subscrptionname: "Drip",
    //     cost: "110.34",
    //     subscriptiontype: "Monthly",
    //   });
    const [progress, setProgress]=useState(false)

 
  
     

  const handleClose = () => setOpen(false);

 const AddSubscriptionDetails=()=>{
        // dispatch(addSubscription(addSubscriptionData));
        // if (SubscriptionResponse?.statusCode === 200) {
            handleClose();
        //   }
    }

    const [getPaymentRequestData, setGetPaymentRequestData]= useState({
        amount:9.00,
        currency:"TTD",
        custom_reference:"94",
        exp:1742294169,
        nbf:dtStr,
        userid:"94",
    })

    const [getPaymentRequestDataTrickle, setGetPaymentRequestDataTrickle]= useState({
        amount:12.00,
        currency:"TTD",
        custom_reference:"94",
        exp:1742294169,
        nbf:dtStr,
        userid:"94",
    })
    const [getPaymentRequestDataSplach, setGetPaymentRequestDataSplach]= useState({
        amount:15.00,
        currency:"TTD",
        custom_reference:"94",
        exp:1742294169,
        nbf:dtStr,
        userid:"94",
    })
    

console.log( getPaymentRequestData, "getPaymentRequestData")
    
    const handleOpen = () => {
        dispatch(getPayment(getPaymentRequestData));
        setOpen(true)
        setProgress(false)

    };
    const handleOpenTricle = () => {
        dispatch(getPayment(getPaymentRequestDataTrickle));
        setOpen(true)
        setProgress(false)

    };
    const handleOpenSplash = () => {
        dispatch(getPayment(getPaymentRequestDataSplach));
        setOpen(true)
        setProgress(false)

    };
    


  const now = new Date();
  const formattedDate = now.toLocaleDateString("en-US");

//   timmer

const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT)

const [status, setStatus] = useState(STATUS.STOPPED)


const secondsToDisplay = secondsRemaining % 60
const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60
const minutesToDisplay = minutesRemaining % 60
const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60

useEffect(()=>{

  setStatus(STATUS.STARTED)

},[status])

useInterval(
  () => {
    if (secondsRemaining > 0) {
      setSecondsRemaining(secondsRemaining - 1)
    } else {
      setStatus(STATUS.STOPPED)
    }
  },
  status === STATUS.STARTED ? 1000 : null,
)

const [alignment, setAlignment] = useState("Level");

const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

    return(
        <div className={Styles.ChangePlanContainer}>
            <div className={Styles.ChangePlan}>
                <div className={Styles.ChangePlanActtiveContainer}>
                <p className={Styles.ChangePlanText}>Change plan</p>
                <ToggleButtonGroup
                      color="primary"
                      value={alignment}
                      exclusive
                      onChange={handleChange}
                      aria-label="Platform"
                      className={Styles.HomePageToggleButtonsContainer}
                    >
                    <CustomToggleButton
                        value="Level"
                        className={Styles.HomePageToggleButtons}
                    >
                        Monthly
                    </CustomToggleButton>
                    <CustomToggleButton
                        value="Usage"
                        className={Styles.HomePageToggleButtons}
                    >
                        Yearly
                    </CustomToggleButton>
            </ToggleButtonGroup>
                </div>
                <div className={Styles.ChangePlanContent}>
                    <div  className={Styles.ChangePlanContentDripContainer}>
                        <div className={Styles.ChangePlanContentDripHeaderContainer}>
                            <p className={Styles.ChangePlanContentDripHeaderContainerTitle}>Drip</p>
                            <p className={Styles.SubscriptionPopUpContentTitleFeildDescription}>DOMESTIC</p>
                            <p className={Styles.SubscriptionPopUpContentTitleFeildDescriptiontanks}>up to 4 tanks</p>                        </div>
                        <div className={Styles.ChangePlanContentDripHeaderContainer}>
                            <div className={Styles.ChangePlanContentDripHeaderContent}>
                                <p className={Styles.ChangePlanContentDripHeaderContentAmount}>$9.00</p>
                                <p className={Styles.ChangePlanContentDripHeaderContentTTD}>TTD</p>
                            </div>
                            <p className={Styles.ChangePlanContentDripHeaderContainerText}>monthly + installation</p>
                        </div>
                        <div className={Styles.ChangePlanContentDripListContainer}>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={PaymentWaySuccess} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Real-time water level indication</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={PaymentWaySuccess} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Mobile alerts - leaks & overflow</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={PaymentWaySuccess} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Annual tank cleaning alert</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={PaymentWaySuccess} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>order truck borne water</p>
                            </div>
                        </div>
                        <div className={Styles.ExceedsContent}>
                            <p  className={Styles.ExceedsContentExceeds}>Exceeds 4 Tanks</p>
                            <p>$10.00 TTD</p>

                        </div>
                        <button className={Styles.ChangePlanContentSplashChoosePlanButton} onClick={()=> {setSubscription("Drip");handleOpen()}}>Choose this plan</button>
                    </div>
                    <div  className={Styles.ChangePlanContentTrickleContainer}>
                        <div className={Styles.ChangePlanContentDripHeaderContainer}>
                            <p className={Styles.ChangePlanContentDripHeaderContainerTitle}>Trickle</p>
                            <p className={Styles.SubscriptionPopUpContentTitleFeildDescription}>DOMESTIC</p>
                            <p className={Styles.SubscriptionPopUpContentTitleFeildDescriptiontanks}>up to 4 tanks</p>                        </div>
                        <div className={Styles.ChangePlanContentDripHeaderContainer}>
                            <div className={Styles.ChangePlanContentDripHeaderContent}>
                                <p className={Styles.ChangePlanContentDripHeaderContentAmount}>$12.00</p>
                                <p className={Styles.ChangePlanContentDripHeaderContentTTD}>TTD</p>
                            </div>
                            <p className={Styles.ChangePlanContentDripHeaderContainerText}>monthly + installation</p>
                        </div>
                        <div className={Styles.ChangePlanContentDripListContainer}>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={TricleImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Real-time water level indication</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={TricleImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Mobile alerts - leaks & overflow</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={TricleImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Annual tank cleaning alert</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={TricleImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>order truck borne water</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={TricleImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Monthly usage report and analysis</p>
                            </div>
                        </div>
                        <div className={Styles.ExceedsContent}>
                            <p  className={Styles.ExceedsContentExceeds}>Exceeds 4 Tanks</p>
                            <p>$10.00 TTD</p>

                        </div>
                        <button className={Styles.ChangePlanContentSplashChoosePlanButton} onClick={()=> { setSubscription("Trickle");handleOpenTricle ()}}>Choose this plan</button>

                    </div>
                    <div  className={Styles.ChangePlanContentSplashContainer}>
                        <div className={Styles.ChangePlanContentDripHeaderContainer}>
                            <p className={Styles.ChangePlanContentDripHeaderContainerTitle}>Splash</p>
                            <p className={Styles.SubscriptionPopUpContentTitleFeildDescription}>DOMESTIC</p>
                            <p className={Styles.SubscriptionPopUpContentTitleFeildDescriptiontanks}>up to 4 tanks</p>                        </div>
                        <div className={Styles.ChangePlanContentDripHeaderContainer}>
                            <div className={Styles.ChangePlanContentDripHeaderContent}>
                                <p className={Styles.ChangePlanContentDripHeaderContentAmount}>$15.00</p>
                                <p className={Styles.ChangePlanContentDripHeaderContentTTD}>TTD</p>
                            </div>
                            <p className={Styles.ChangePlanContentDripHeaderContainerText}>monthly + installation</p>
                        </div>
                        <div className={Styles.ChangePlanContentSplashChoosePlanButtonActive}>
                            <hr className={Styles.ChangePlanContentSplashChoosePlanButtonLine}/>
                           
                        </div>
                        <div className={Styles.ChangePlanContentDripListContainer}>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={SplashImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Real-time water level indication</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={SplashImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Mobile alerts - leaks & overflow</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={SplashImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Annual tank cleaning alert</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={SplashImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>order truck borne water</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={SplashImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Monthly usage report and analysis</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={SplashImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Annual system check up</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={SplashImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Free derelict tank disposal</p>
                            </div>
                        </div>
                        <div className={Styles.ExceedsContent}>
                            <p  className={Styles.ExceedsContentExceeds}>Exceeds 4 Tanks</p>
                            <p>$10.00 TTD</p>

                        </div>
                        <button className={Styles.ChangePlanContentSplashChoosePlanButton} onClick={()=> {setSubscription("Splash");handleOpenSplash()}}>Choose this plan</button>
                        <div className={Styles.ChangePlanContentSplashChoosePlanButtonActiveContainer}>
                                <p className={Styles.ChangePlanContentSplashChoosePlanButtonActiveContainerText}> Active upto</p>
                                <p className={Styles.ChangePlanContentSplashChoosePlanButtonActiveContainerDate}> 02-12-2023</p>
                            </div>
                    </div>
                    <div  className={Styles.ChangePlanContentSplashContainer}>
                        <div className={Styles.ChangePlanContentDripHeaderContainer}>
                            <p className={Styles.ChangePlanContentDripHeaderContainerTitle}>Maas</p>
                            <p className={Styles.ChangePlanContentDripHeaderContainerText}>NON-DOMESTIC</p>
                            <p className={Styles.SubscriptionPopUpContentTitleFeildDescriptiontanks}>up to 4 tanks</p>
                        </div>
                        <div className={Styles.ChangePlanContentDripHeaderContainer}>
                            <div className={Styles.ChangePlanContentDripHeaderContent}>
                                <p className={Styles.ChangePlanContentDripHeaderContentAmount}>$90.00</p>
                                <p className={Styles.ChangePlanContentDripHeaderContentTTD}>TTD</p>
                            </div>
                            <p className={Styles.ChangePlanContentDripHeaderContainerText}>monthly + installation</p>
                        </div>
                        <div className={Styles.ChangePlanContentSplashChoosePlanButtonActive}>
                            <hr className={Styles.ChangePlanContentSplashChoosePlanButtonLine}/>
                            <div className={Styles.ChangePlanContentSplashChoosePlanButtonActiveContainer}>
                                <p className={Styles.ChangePlanContentSplashChoosePlanButtonActiveContainerText}> Active upto</p>
                                <p className={Styles.ChangePlanContentSplashChoosePlanButtonActiveContainerDate}> 02-12-2023</p>
                            </div>
                        </div>
                        <div className={Styles.ChangePlanContentDripListContainer}>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={SplashImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Real-time water level indication</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={SplashImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Mobile alerts - leaks & overflow</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={SplashImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Annual tank cleaning alert</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={SplashImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>order truck borne water</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={SplashImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Monthly usage report and analysis</p>
                            </div>
                        </div>
                        <div className={Styles.ExceedsContent}>
                            <p  className={Styles.ExceedsContentExceeds}>Exceeds 4 Tanks</p>
                            <p>$10.00 TTD</p>

                        </div>
                        <button className={Styles.ChangePlanContentSplashChoosePlanButton} onClick={()=> {setSubscription("Splash");handleOpenSplash()}}>Choose this plan</button>

                    </div>
                </div>
            </div>
           
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            {progress === false ?
                <div className={Styles.SubscriptionPopUpMadelContainer}>
                    <div className={Styles.SubscriptionPopUpMadelContainerTitle}>
                    <p className={Styles.SubscriptionPopUpMadelContainerTitleText}>Are you sure you want to choose this plan instead?</p>
                    <p  className={Styles.SubscriptionPopUpMadelContainerDescription}>Your new plan starts now. You’ll pay <span className={Styles.SubscriptionPopUpMadelContainerDescriptionMoney}>{subscription === "Drip" ? ("₹$9.00"):subscription === "Trickle" ? ("₹$12.00/"):("₹$15.00/")}/month</span> starting {formattedDate}. You agree that your Wetflix membership will continue and that we will charge the updated monthly fee until you cancel.</p>
                    </div>
                    <div className={Styles.SubscriptionPopUpContent}>
                        <div className={Styles.SubscriptionPopUpContentTitleFeild}>
                            <p className={Styles.SubscriptionPopUpContentTitleFeildText}>{subscription === "Drip" ? ("Drip"):subscription === "Trickle" ? ("Trickle"):("Splash")}</p>
                            <p className={Styles.SubscriptionPopUpContentTitleFeildDescription}>DOMESTIC</p>
                            <p className={Styles.SubscriptionPopUpContentTitleFeildDescriptiontanks}>up to 4 tanks</p>

                        </div>
                        <div className={Styles.SubscriptionPopUpContentPlanFeild}>
                        {subscription === "Drip" ? (
                        <><div className={Styles.ChangePlanContentDripListContent}>
                                    <img src={PaymentWaySuccess} alt=""/>
                                    <p className={Styles.ChangePlanContentDripListContentText}>Real-time water level indication</p>
                                </div>
                                <div className={Styles.ChangePlanContentDripListContent}>
                                    <img src={PaymentWaySuccess} alt=""/>
                                    <p className={Styles.ChangePlanContentDripListContentText}>Mobile alerts - leaks & overflow</p>
                                </div>
                                <div className={Styles.ChangePlanContentDripListContent}>
                                    <img src={PaymentWaySuccess} alt=""/>
                                    <p className={Styles.ChangePlanContentDripListContentText}>Annual tank cleaning alert</p>
                                </div>
                                <div className={Styles.ChangePlanContentDripListContent}>
                                    <img src={PaymentWaySuccess} alt=""/>
                                    <p className={Styles.ChangePlanContentDripListContentText}>Order truck borne water</p>
                                </div></>
                            ):subscription === "Trickle" ? (
                            <><div className={Styles.ChangePlanContentDripListContainer}>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={TricleImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Real-time water level indication</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={TricleImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Mobile alerts - leaks & overflow</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={TricleImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Annual tank cleaning alert</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={TricleImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>order truck borne water</p>
                            </div>
                            <div className={Styles.ChangePlanContentDripListContent}>
                                <img src={TricleImage} alt=""/>
                                <p className={Styles.ChangePlanContentDripListContentText}>Monthly usage report and analysis</p>
                            </div>
                        </div>
                            </>
                            ):(
                                <><div className={Styles.ChangePlanContentDripListContainer}>
                                <div className={Styles.ChangePlanContentDripListContent}>
                                    <img src={SplashImage} alt=""/>
                                    <p className={Styles.ChangePlanContentDripListContentText}>Real-time water level indication</p>
                                </div>
                                <div className={Styles.ChangePlanContentDripListContent}>
                                    <img src={SplashImage} alt=""/>
                                    <p className={Styles.ChangePlanContentDripListContentText}>Mobile alerts - leaks & overflow</p>
                                </div>
                                <div className={Styles.ChangePlanContentDripListContent}>
                                    <img src={SplashImage} alt=""/>
                                    <p className={Styles.ChangePlanContentDripListContentText}>Annual tank cleaning alert</p>
                                </div>
                                <div className={Styles.ChangePlanContentDripListContent}>
                                    <img src={SplashImage} alt=""/>
                                    <p className={Styles.ChangePlanContentDripListContentText}>order truck borne water</p>
                                </div>
                                <div className={Styles.ChangePlanContentDripListContent}>
                                    <img src={SplashImage} alt=""/>
                                    <p className={Styles.ChangePlanContentDripListContentText}>Monthly usage report and analysis</p>
                                </div>
                                <div className={Styles.ChangePlanContentDripListContent}>
                                    <img src={SplashImage} alt=""/>
                                    <p className={Styles.ChangePlanContentDripListContentText}>Annual system check up</p>
                                </div>
                                <div className={Styles.ChangePlanContentDripListContent}>
                                    <img src={SplashImage} alt=""/>
                                    <p className={Styles.ChangePlanContentDripListContentText}>Free derelict tank disposal</p>
                                </div>
                            </div></>
                            )}
                                
                        </div>
                        <div className={Styles.SubscriptionPopUpContentMoneyFeild}>
                            <p className={Styles.SubscriptionPopUpContentMoneyFeildPayment}>{subscription === "Drip" ? ("₹$9.00"):subscription === "Trickle" ? ("₹$12.00/"):("₹$15.00/")}<span className={Styles.SubscriptionPopUpContentMoneyFeildPaymentCountry}>TTD</span></p>
                            <p className={Styles.SubscriptionPopUpContentMoneyFeildTime}>monthly + installation</p>
                        </div>
                    </div>
                    <div className={Styles.SubscriptionPopUpContentButtonContainer}>
                            <button className={Styles.SubscriptionPopUpContentButton} onClick={()=>handleClose()}>Cancel</button>
                            <button className={Styles.SubscriptionPopUpContentButton}  onClick={()=> setProgress(true)}
                            ><Link to={`https://www.fygaro.com/en/pb/f9668c9d-19cb-48c6-b70a-1bec2ca0a1bd/+?jwt=${getPaymentResponse?.data?.jwtToken}`} target="_blank" className={Styles.paymentLiink}>Continue to payment</Link></button>
                    </div>
                </div>:<div className={Styles.paymentTimmerContainer}>
                    <p className={Styles.paymentTimmerContainerText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eget varius sem. Pellentesque rutrum lectus in risus consequat, sit amet pellentesque massa egestas. Curabitur non sapien tellus. Praesent ante leo.</p>
                    <CircularProgress />
                    <div style={{padding: 20}}>
                        {twoDigits(hoursToDisplay)}:{twoDigits(minutesToDisplay)}:
                        {twoDigits(secondsToDisplay)}
                    </div>
                    {INITIAL_COUNT === 5   ? <button>close</button>:""}
                </div>
            }
        </Box>
        </Modal>
        </div>
    )
}
export default ChangePlan